<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('item_table_settings') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row" v-if="data">
        <div class="col-md-6 mb-5" v-for="(row, idx) in data" :key="'row'+idx">
          <div class="card card-custom">
            <div class="card-body">
              <h5 class="mb-3">
                {{ row.name }}
              </h5>
              <div class="row">
                <div class="col-lg-4 mb-4" v-for="(input, idx) in row.available_columns" :key="'available_columns'+ idx">
                  <b-form-checkbox v-model="input.value" name="check-button" size="md">
                    {{ input.label }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6">
            <button class="btn btn-primary mr-2" type="reset" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-items-configuration",
  data() {
    return {
      mainRoute: 'items/item_table_settings',
      mainRouteDependency: 'base/dependency',
      data: {
      },
      validation: null,
    };
  },

  methods: {
    save() {
      const data = this.data;
      // remove id key from data
      data.forEach((item) => {
        delete item.id;
      });
      delete data.id;
      ApiService.post(`${this.mainRoute}`, {
        ...data
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
          });
    },
    async getData() {
      ApiService.get(`${this.mainRoute}`).then((response) => {
        this.data = response.data.data.data;
      }).then(() => {});
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.item_configuration')},{title: this.$t("item_table_settings")}]);
  },
  created() {
    this.getData();
  }
};
</script>
